import { Icon } from "@chakra-ui/icons";

export function ScanIcon(props) {
  return (
    <Icon
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17292 4.73206C5.94077 4.73206 4.94191 5.75089 4.94191 7.00769V10.6993H1.96722V7.00769C1.96722 4.07516 4.2979 1.69788 7.17292 1.69788H10.4074V4.73206H7.17292Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8272 29.2679C28.0594 29.2679 29.0582 28.2491 29.0582 26.9923L29.0582 23.3007L32.0329 23.3007L32.0329 26.9923C32.0329 29.9248 29.7022 32.3021 26.8272 32.3021L23.5927 32.3021L23.5927 29.2679L26.8272 29.2679Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0582 7.00769C29.0582 5.75089 28.0593 4.73206 26.8271 4.73205L23.2079 4.73205L23.2079 1.69788L26.8271 1.69788C29.7022 1.69788 32.0328 4.07516 32.0328 7.00769L32.0328 10.3068L29.0582 10.3068L29.0582 7.00769Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.94185 26.7961C4.94185 28.0529 5.94071 29.0717 7.17286 29.0717L10.7921 29.0717L10.7921 32.1059L7.17286 32.1059C4.29783 32.1059 1.96716 29.7286 1.96716 26.7961L1.96716 23.497L4.94185 23.497L4.94185 26.7961Z"
        fill="white"
      />
    </Icon>
  );
}
