import { CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Circle, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ScanIcon } from "../components/ScanIcon";
import { getImageURL } from "../utils/getImageURL";

import "react-lazy-load-image-component/src/effects/blur.css";

export function Document() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const handleNewScan = React.useCallback(() => {
    history.push("/");
  }, [history]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleNewScan();
    }, 120000); // after 2 minutes back to new scan
    return () => clearTimeout(timer);
  }, [handleNewScan]);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  return (
    <Box p="6" minHeight="100vh" w="full">
      <Box>
        {location.state?.frontImage && (
          <LazyLoadImage
            alt="Front image"
            effect="blur"
            src={getImageURL(location.state.frontImage)} // use normal <img> attributes as props
            style={{
              minHeight: 327,
              objectFit: "contain",
              margin: "0 auto",
            }}
          />
        )}
      </Box>
      <Box mt={4}>
        {location.state?.backImage && (
          <LazyLoadImage
            alt="Back image"
            effect="blur"
            src={getImageURL(location.state.backImage)} // use normal <img> attributes as props
            style={{
              minHeight: 327,
              objectFit: "contain",
              margin: "0 auto",
            }}
          />
        )}
      </Box>
      <Box mt={4}>
        <Stack direction="row" align="center" justify="center">
          <Circle size="64px" bg="green.400" color="white">
            <CheckIcon />
          </Circle>
          <Text fontSize="xl" fontWeight="semibold">
            {searchParams?.name}
          </Text>
        </Stack>
      </Box>
      <Box textAlign="center">
        <Button
          mt={8}
          rightIcon={
            <ScanIcon style={{ marginLeft: 8 }} width="24px" height="24px" />
          }
          bg="#6C6C6C"
          color="white"
          onClick={handleNewScan}
        >
          New Scan
        </Button>
      </Box>
    </Box>
  );
}
