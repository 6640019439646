import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Center,
  Circle,
  Heading,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Spacer,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { MdFlipCameraIos } from "react-icons/md";
import * as React from "react";
import { Link } from "react-router-dom";
import ReactQrReader from "react-qr-reader";
import { ScanIcon } from "../components/ScanIcon";
import { cameraFacingMode } from "../constants/common";
import { getImageURL } from "../utils/getImageURL";
import { getLocalStorage, key } from "../utils/storage";
import * as api from "../services/api";
import { useAuth } from "../contexts/AuthContext";
import viewVaccineCardImg from "../assets/images/view-vaccine-card.svg";
import { getFullname } from "../utils/getFullname";

export function QrReader() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [result, setResult] = React.useState(null);
  const [document, setDocument] = React.useState(null);
  const [facingMode, setFacingMode] = React.useState("environment");
  const [isOpenAlert, setIsOpenAlert] = React.useState(false);
  const [commercialName] = React.useState(() => {
    try {
      const cname = getLocalStorage(key.commercialName);
      return cname;
    } catch (error) {}
  });
  const cancelRef = React.useRef();
  const toast = useToast();
  const auth = useAuth();

  const handleScan = async (data) => {
    if (data) {
      const regex = new RegExp("^V-.*-K-.*", "g");
      const isValid = regex.test(data);

      if (!isValid) {
        toast({
          title: "QR Code is invalid.",
          description: "Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const res = await api.scanQr(data);
      // alert(JSON.stringify(res, null, 2));

      if (!res?.data) {
        toast({
          title: `${res.message ?? "Something went wrong."}`,
          description: "Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      const document = res.data?.documents.find(
        (document) => document.type === 1
      );

      setDocument(document);
      setResult(res.data);
      onOpen();
    }
  };

  const handleError = (err) => {
    // console.error(err);
    toast({
      title: `Camera not supported`,
      description: "Please check your device.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  const handleNewScan = () => {
    onClose();
  };

  const handleSwitchCamera = () => {
    setFacingMode((prev) =>
      prev === cameraFacingMode.ENVIRONMENT
        ? cameraFacingMode.USER
        : cameraFacingMode.ENVIRONMENT
    );
  };

  const onCloseAlert = () => setIsOpenAlert(false);

  const handleLogOut = () => {
    auth.logout();
  };

  return (
    <Box pos="relative">
      <ReactQrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
        resolution={900}
        facingMode={facingMode}
      />
      <Box
        position="absolute"
        zIndex="10"
        top="0"
        left="0"
        right="0"
        bg="blackAlpha.400"
        px="32px"
        py="8px"
      >
        <Flex align="center">
          <Box>
            <Heading
              size="sm"
              color="white"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {commercialName}
            </Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              size="sm"
              variant="link"
              colorScheme="red"
              onClick={() => setIsOpenAlert(true)}
            >
              Log Out
            </Button>
          </Box>
        </Flex>
      </Box>
      <Box position="absolute" zIndex="10" bottom="0" right="0">
        <IconButton
          variant="link"
          aria-label="Camera"
          fontSize="24px"
          icon={<MdFlipCameraIos fontSize="100%" />}
          color="white"
          onClick={handleSwitchCamera}
        />
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        size="xs"
        isOpen={isOpenAlert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Log Out</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Are you sure you want to log out?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseAlert}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleLogOut}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Modal
        closeOnOverlayClick={false}
        size="xs"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalBody py={6}>
            <Center>
              <VStack spacing={8}>
                <Avatar
                  bg={result?.documents.length ? "green.400" : "red.600"}
                  padding={2}
                  size="full"
                  boxSize="192px"
                  fontSize="1.5rem"
                  src={getImageURL(result?.user?.avatar)}
                />

                <Circle
                  size="64px"
                  bg={result?.documents.length ? "green.400" : "red.600"}
                  color="white"
                  style={{ outline: "none" }}
                >
                  {result?.documents.length ? <CheckIcon /> : <CloseIcon />}
                </Circle>

                {document ? (
                  <Box>
                    <Link
                      to={{
                        pathname: "/document",
                        search: `?name=${getFullname(
                          result?.user?.firstName,
                          result?.user?.lastName
                        )}`,
                        state: {
                          frontImage: document?.frontImage,
                          backImage: document?.backImage,
                        },
                      }}
                    >
                      <Image src={viewVaccineCardImg} alt="View vaccine card" />
                    </Link>
                  </Box>
                ) : null}
              </VStack>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              isFullWidth
              rightIcon={
                <ScanIcon
                  style={{ marginLeft: 8 }}
                  width="24px"
                  height="24px"
                />
              }
              bg="#6C6C6C"
              color="white"
              onClick={handleNewScan}
            >
              New Scan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
