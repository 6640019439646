import { Icon } from "@chakra-ui/react";
import * as React from "react";

export function Divider(props) {
  return (
    <Icon
      viewBox="0 0 310 2"
      width="310"
      height="2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="155" cy="1" rx="155" ry="1" fill="#C20000" />
    </Icon>
  );
}
