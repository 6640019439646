export const key = {
  token: "t",
  frontImage: "front_image",
  backImage: "back_image",
  cacheUser: "cache-user",
  commercialName: "cname",
  user: "user",
};

export function getToken() {
  const { value = "" } = getLocalStorage(key.token);
  if (value) return value;
}

export function getLocalStorage(key) {
  const data = window.localStorage.getItem(key);
  if (data) return JSON.parse(data);
}

export function setLocalStorage(key, value) {
  if (getLocalStorage(key)) {
    window.localStorage.removeItem(key);
  }

  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorage(key) {
  const data = window.sessionStorage.getItem(key);
  if (data) return data;
}

export function setSessionStorage(key, value) {
  if (getSessionStorage(key)) {
    window.sessionStorage.removeItem(key);
  }

  window.sessionStorage.setItem(key, value);
}
