export function getFullname(firstName, lastName) {
  if (firstName && !lastName) {
    return firstName;
  } else if (!firstName && lastName) {
    return lastName;
  } else if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else {
    return "";
  }
}
