import { baseURL, ipification } from "../config";
import { getToken } from "../utils/storage";

export function scanQr(qrCode) {
  const token = getToken();

  return window
    .fetch(`${baseURL}/users/scan/document`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ value: qrCode }),
    })
    .then((res) => res.json());
}

export function login(commercialName, phoneNumber, code) {
  return window
    .fetch(`${baseURL}/users/staff/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        commercialName,
        countryCode: 855,
        phoneNumber: +phoneNumber,
        code: code
      }),
    })
    .then((res) => res.json());
}

export function confirmPinCode(phoneNumber, verificationCode) {
  return window
    .fetch(`${baseURL}/users/staff/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryCode: 855,
        phoneNumber: +phoneNumber,
        verificationCode: +verificationCode,
      }),
    })
    .then((res) => res.json());
}

export function requestPinCode() {
  return window
    .fetch(`${baseURL}/users/staff/document`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
    .then((res) => res.json());
}

export function checkCoverage() {
  return window
    .fetch(`${ipification.url}/auth/realms/ipification/coverage?client_id=${ipification.clientId}`, {
      method: "GET",
    })
    .then((res) => {
      return res.json();
    });
}

export function initialAuth(phoneNumber) {
  var url = new URL(`${ipification.url}/auth/realms/ipification/protocol/openid-connect/auth`)
  var params = {
    'response_type': 'code',
    'client_id': ipification.clientId,
    'redirect_uri': ipification.redirectURL,
    'scope': 'openid',
    'login_hint': phoneNumber
  }
  url.search = new URLSearchParams(params).toString();
  return window.location.replace(url);

  // .fetch(url, {
  //   method: "GET",
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //   }
  // })
  // .then((res) => {
  //   const responseURL = new URL(res.url);
  //   const urlParams = new URLSearchParams(responseURL.search);
  //   const code = urlParams.get('code');
  //   return code;
  // });
}