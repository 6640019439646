import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      a: {
        outline: "none",
        boxShadow: "none",
      },
    },
  },
  fonts: {
    heading: "Museo Sans",
    body: "Museo Sans",
  },
});

export default theme;
