import { useToast } from "@chakra-ui/react";
import * as React from "react";
import * as api from "../services/api";
import { key, getLocalStorage, setLocalStorage } from "../utils/storage";

const authContext = React.createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return React.useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = React.useState(() => {
    try {
      const now = new Date();
      const t = getLocalStorage(key.token);

      if (!t?.expiry || now.getTime() > t.expiry) {
        window.localStorage.removeItem(key.token);
        return null;
      }

      return t.value;
    } catch (error) {
      window.localStorage.removeItem(key.token);
      return null;
    }
  });
  const [isOTP, setOTP] = React.useState(false);
  const [error, setError] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const toast = useToast();

  React.useEffect(() => {
    const setOffline = () => {
      toast({
        title: `You're offline.`,
        description: "Please check your network.",
        status: "warning",
        isClosable: true,
        position: "top",
      });
    };

    window.addEventListener("offline", setOffline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
    };
  }, [toast]);

  const confirmPinCode = async (verificationCode, cb) => {
    const res = await api.confirmPinCode(phoneNumber, verificationCode);
    if (!res.data) {
      return setError(res.error);
    }
    const ttl = 60 * 60 * 24 * 1000 * 1; // 60 minutes * 60 seconds * 24 hours * 1000 (for milliseconds) * 1 days
    const now = new Date();
    const value = {
      value: res.data.accessToken,
      expiry: now.getTime() + ttl,
    };

    setLocalStorage(key.commercialName, res.data.firstName);
    setLocalStorage(key.token, value);
    setUser(res.data.accessToken);
    window.localStorage.removeItem(key.user);
    cb();
  };

  const cacheLogin = async (code, cb) => {
    const cacheUser = getLocalStorage(key.cacheUser);
    if (!cacheUser) {
      cb(false);
      return;
    }
    const res = await api.login(
      cacheUser.commercialName,
      cacheUser.phoneNumber,
      code
    );

    if (!res.data) {
      window.localStorage.removeItem(key.cacheUser);
      toast({
        title: `${res?.message ?? "Something went wrong."}`,
        description: "Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (res.data.accessToken) {
      const ttl = 60 * 60 * 24 * 1000 * 1; // 60 minutes * 60 seconds * 24 hours * 1000 (for milliseconds) * 1 days
      const now = new Date();
      const value = {
        value: res.data.accessToken,
        expiry: now.getTime() + ttl,
      };
      window.localStorage.removeItem(key.cacheUser);
      setLocalStorage(key.commercialName, res.data.firstName);
      setLocalStorage(key.token, value);
      setUser(res.data.accessToken);
      cb(true);
      return;
    }
    setPhoneNumber(cacheUser.phoneNumber);
    window.localStorage.removeItem(key.cacheUser);
    setOTP(true);
    cb(false);
  };

  const login = async (commercialName, phoneNumber, cb) => {
    const coverage = await api.checkCoverage();

    setLocalStorage(key.user, { commercialName, phoneNumber });

    if (coverage.available) {
      setLocalStorage(key.cacheUser, {
        commercialName: commercialName,
        phoneNumber: phoneNumber,
      });
      api.initialAuth(`855${phoneNumber}`);
      return;
    }
    const res = await api.login(commercialName, phoneNumber, null);
    if (!res.data) {
      toast({
        title: `${res?.message ?? "Something went wrong."}`,
        description: "Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setPhoneNumber(phoneNumber);
    setOTP(true);
  };

  const logout = () => {
    window.localStorage.clear();
    setOTP(false);
    setUser(null);
  };

  const resendPinCode = async () => {
    const values = getLocalStorage(key.user);
    const res = await api.login(
      values.commercialName,
      values.phoneNumber,
      null
    );
    if (!res.data) {
      toast({
        title: `${res?.message ?? "Something went wrong."}`,
        description: "Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
  };

  return {
    isOTP,
    user,
    confirmPinCode,
    login,
    logout,
    cacheLogin,
    error,
    resendPinCode,
  };
}
