const domain = process.env.REACT_APP_DOMAIN;
const path = process.env.REACT_APP_PATH;
const baseURL = `${domain}${path}`;
const imageURL = process.env.REACT_APP_IMAGE_PATH;

const ipification = {
  url: process.env.REACT_APP_IPIFICATION_URL,
  clientId: process.env.REACT_APP_IPIFICATION_CLIENT_ID,
  redirectURL: process.env.REACT_APP_IPIFICATION_REDIRECT_URL
}
const config = {
  domain,
  path,
  baseURL,
  imageURL,
  ipification,
};

export { domain, path, baseURL, imageURL, ipification };
export default config;
