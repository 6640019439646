import { HStack, Image, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import wonderpass from "../assets/images/wonderpass.svg";
import checkIcon from "../assets/images/check.svg";

export function Heading() {
  return (
    <>
      <Stack direction="column" spacing="6px">
        <Image src={wonderpass} width="256px" height="auto" alt="Wonderpass" />
        <HStack>
          <Text fontSize="2.1rem" fontWeight="bold">
            VaccineCheck
          </Text>
          <Image src={checkIcon} boxSize="24px" alt="CheckIcon" />
        </HStack>
      </Stack>
    </>
  );
}
