export const documentTypes = [
  "Identity Document",
  "Covid-19 Vaccine Certificate",
];

export const documentStatus = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const cameraFacingMode = {
  USER: "user",
  ENVIRONMENT: "environment",
};
