import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export function OTPForm() {
  const history = useHistory();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
  } = useForm();

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      setValue("verificationCode", "");
    }
  }, [isSubmitSuccessful, setValue]);

  const handleResend = () => {
    auth.resendPinCode();
  };

  const onSubmit = (data) => {
    auth.confirmPinCode(data.verificationCode, () => {
      history.push("/");
    });
  };

  return (
    <Box>
      <Box px="6">
        <Text
          as="h1"
          fontSize="2xl"
          fontWeight="semibold"
          lineHeight="short"
          mb={4}
        >
          Phone Activation
        </Text>
        <Text>Enter the 6 digit activation code we sent you by SMS</Text>
      </Box>

      <chakra.form mt={4} onSubmit={handleSubmit(onSubmit)}>
        <FormControl px="6" isInvalid={errors.verificationCode}>
          <FormLabel srOnly htmlFor="single-factor-code-text-field">
            Verification Code
          </FormLabel>
          <Input
            type="tel"
            id="single-factor-code-text-field"
            // autocomplete="one-time-code"
            {...register("verificationCode", {
              required: "Verification code is required",
              minLength: {
                value: 6,
                message: "Verification code must be 6 digits",
              },
              maxLength: {
                value: 6,
                message: "Verification code must be 6 digits",
              },
            })}
          />
          <FormErrorMessage>
            {errors.verificationCode && errors.verificationCode.message}
          </FormErrorMessage>
        </FormControl>

        {auth.error ? (
          <Alert bg="orange.400" color="white" status="warning" p="6" mt={4}>
            <AlertDescription>
              The code entered is not correct. Try again.
            </AlertDescription>
          </Alert>
        ) : null}

        <Stack
          px="6"
          mt={8}
          direction="row"
          justify="center"
          align="center"
          spacing="8"
        >
          <VStack>
            <Text>Didn’t receive a code?</Text>
            <Button color="red.600" variant="link" onClick={handleResend}>
              Send another
            </Button>
          </VStack>
          <Button colorScheme="red" isLoading={isSubmitting} type="submit">
            Confirm
          </Button>
        </Stack>
      </chakra.form>
    </Box>
  );
}
