import * as React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthGuard } from "./components/AuthGuard";
import { Document } from "./pages/Document";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/document">
          <AuthGuard>
            <Document />
          </AuthGuard>
        </Route>
        <Route path="/">
          <AuthGuard>
            <Home />
          </AuthGuard>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
