import { Global } from "@emotion/react";
import MuseoSans300 from "../assets/fonts/MuseoSans_300.otf";
import MuseoSans500 from "../assets/fonts/MuseoSans_500.otf";
import MuseoSans700 from "../assets/fonts/MuseoSans_700.otf";

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Museo Sans';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('${MuseoSans300}') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Museo Sans';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('${MuseoSans500}') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Museo Sans';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${MuseoSans700}') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      html, body {
        font-family: 'Museo Sans', sans-serif;
      }
      `}
  />
);
export default Fonts;
