import {
  Box,
  Button,
  Image,
  Flex,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { Heading } from "../components/Heading";
import { QrReader } from "../components/QrReader";
import merchantImg from "../assets/images/merchant.jpeg";

export function Home() {
  return (
    <main>
      <Stack
        direction="column"
        mx="auto"
        minHeight="100vh"
        w={["100%", "100%", "100%", "30em"]}
      >
        <QrReader />

        <Box flex="1">
          <Box py="24px" px="32px">
            <Heading />
          </Box>
        </Box>

        <Box>
          <Flex color="white">
            <Box width="40%" height="auto" bg="red.600">
              <Image
                loading="lazy"
                boxSize="100%"
                objectFit="cover"
                src={merchantImg}
              />
            </Box>
            <Box flex="1" bg="red.600">
              <VStack p={4} pb={8} spacing={4} h="100%">
                <Text fontSize="sm">
                  Wonderpass Merchants can accept digital payments, make
                  promotions, and more!
                </Text>
                <Stack h="100%" justify="center" align="center">
                  <Button
                    as="a"
                    href="https://partners.wonderpass.asia/"
                    target="_blank"
                    rel="noopener"
                    size="sm"
                    bg="white"
                    color="gray.900"
                  >
                    Apply Now!
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Stack>
    </main>
  );
}
