import {
  Box,
  Button,
  Stack,
  Input,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { getLocalStorage, key } from "../utils/storage";

export function LoginForm() {
  let history = useHistory();
  let auth = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let code = params.get("code");
    if (code && auth && history) {
      auth.cacheLogin(code, (value) => {
        if (value) {
          history.push("/");
        }
      });
    } else {
      const cacheUser = getLocalStorage(key.cacheUser);
      if (cacheUser) {
        auth.cacheLogin(null, (value) => {
          if (value) {
            history.push("/");
          }
        });
      }
    }
  }, [auth, history]);

  const onSubmit = (values) => {
    auth.login(values.businessName, values.phoneNumber, () => {
      history.push("/");
    });
  };

  console.log("isSubmitting", isSubmitting);

  return (
    <Box p="6" pt="0">
      <Text
        as="h1"
        fontSize="xl"
        fontWeight="semibold"
        lineHeight="short"
        mb={4}
      >
        Register and validate your customers vaccination status.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Stack spacing="4">
          <FormControl isInvalid={errors.businessName}>
            <FormLabel srOnly htmlFor="businessName">
              Business Name
            </FormLabel>
            <Input
              id="businessName"
              placeholder="Business Name"
              {...register("businessName", {
                required: "Business name is required",
              })}
            />
            <FormErrorMessage>
              {errors.businessName && errors.businessName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.phoneNumber}>
            <FormLabel srOnly htmlFor="phoneNumber">
              Phone Number
            </FormLabel>
            <InputGroup>
              <InputLeftAddon children="+855" />
              <Input
                type="tel"
                id="phoneNumber"
                placeholder="Phone Number"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^(\d{8}|\d{9})$/,
                    message: "Phone number is invalid",
                  },
                })}
                onChange={(e) => {
                  const valueString = e.target.value.replace(/\D|^0+/g, "");
                  setValue("phoneNumber", valueString);
                }}
              />
            </InputGroup>

            <FormErrorMessage>
              {errors.phoneNumber && errors.phoneNumber.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            mt={4}
            isLoading={isSubmitting}
            type="submit"
            bg="red.600"
            color="white"
            _hover={{}}
            _active={{}}
          >
            Start Scanning
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
