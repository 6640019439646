import { Box, chakra, Flex, Image, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "../components/Divider";
import { Heading } from "../components/Heading";
import { LoginForm } from "../components/LoginForm";
import { OTPForm } from "../components/OTPForm";
import { useAuth } from "../contexts/AuthContext";
import motImg from "../assets/images/ministery-of-tourisim-logo.png";

export function Login() {
  const auth = useAuth();
  if (auth?.user) {
    return <Redirect to="/" />;
  }

  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      maxW="xl"
      mx="auto"
    >
      <Box as="header" p="6" pb="0">
        <Stack direction="column" spacing="16px" mb={4}>
          <Heading />
          <Divider />
        </Stack>
      </Box>

      <Box as="main" flex="1">
        {auth.isOTP ? <OTPForm /> : <LoginForm />}
      </Box>
      <chakra.footer p="6" textAlign="center" fontSize="x-small">
        <Box maxW="2xs" mx="auto">
          <Flex justify="center" align="center">
            <Box width="full">
              <Text fontSize="md" textAlign="center">
                Exclusive Partner of the Ministry of Tourism
              </Text>
            </Box>
            <Box width="full">
              <Image src={motImg} boxSize="100%" alt="Ministery of Tourism" />
            </Box>
          </Flex>
        </Box>
        <Text mt={4}>
          © 2021 Wonderpass Technology Co., Ltd. <br /> All rights reserved.
        </Text>
      </chakra.footer>
    </Box>
  );
}
